import React, { useState } from 'react';

// Expa component that expands/collapses text
const Expand = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false); // state to track if expanded

  const toggleExpand = () => {
    setIsExpanded(!isExpanded); // Toggle the state between expanded and collapsed
  };

  return (
    <span>
      {!isExpanded ? (
        <span>
          <a href="#" onClick={toggleExpand}>More...</a>
        </span>
      ) : (
        <span>
          {children} {/* Display the content */}
          <a href="#" onClick={toggleExpand}> Less</a> {/* Add link to collapse */}
        </span>
      )}
    </span>
  );
};

export default Expand;
